import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="text-gray-700 body-font">
    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <Link
        to="/"
        className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
      >
        <span className="text-3xl">
          Margaret <span className="text-blue-600">Heap</span>
        </span>
      </Link>
      <nav className="md:ml-auto flex-col md:flex-row flex flex-wrap items-center text-base justify-center">
        <Link to="/contact" className="mr-5 hover:text-gray-900">
          Contact
        </Link>
        <Link to="/experience" className="mr-5 hover:text-gray-900">
          Experience
        </Link>
        <Link to="/fees" className="mr-5 hover:text-gray-900">
          Fees
        </Link>
        <Link to="/privacy" className="mr-5 hover:text-gray-900">
          Privacy Policy
        </Link>
        <Link to="/supervision" className="mr-5 hover:text-gray-900">
          Supervision
        </Link>
      </nav>
      <Link
        to="/what-to-expect"
        className="inline-flex items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0"
      >
        What To Expect
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-4 h-4 ml-1"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
